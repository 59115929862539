/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import './app_bar.scss';

import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import {Link as ScrollLink, animateScroll } from 'react-scroll';
import { Link } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import logo from 'images/logotyp-min.png';
import { ReactComponent as HamburgerIcon } from './hamburger.svg';
import { ReactComponent as CloseIcon } from './close.svg';
import { ReactComponent as SignInIcon } from './sign-in.svg';

import navSections from '../../data/navigation/navSections';

// const GOOGLE_MAPS_KEY = 'AIzaSyAu3u3iYkyiOeQxWLqdpm_gZdu3YQrmJDU';
const mainClassName = 'app-bar';

const AppBar = ({ inMainPage = false }) => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [showMenu, setShowMenu] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  // const [isCA, setIsCA] = useState(false);
  // const [isCanada, setIsCanada] = useState(false);

  const onScroll = useCallback(
    (event) => {
      if (typeof window === 'undefined') return false;
      const scrollTop = window?.pageYOffset || window?.document?.documentElement?.scrollTop;
      if (scrollTop > lastScrollTop) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }

      setLastScrollTop(scrollTop);
      setIsOpen(false);
    },
    [setShowMenu, lastScrollTop, setIsOpen]
  );

  // useEffect(() => {
  //   searchParams();
  //   initGeolocation();
  // }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onScroll, { passive: true });

      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }
  }, [onScroll]);

  // const searchParams = () => {
  //   const queryString = window.location.search;
  //   const urlParams = new URLSearchParams(queryString);

  //   if(urlParams.has('canada')) {
  //     setIsCanada(true);
  //   }
  // };

  // const initGeolocation = () => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       function success(position) {
  //         reverseGeocodingWithGoogle(position.coords.latitude, position.coords.longitude);
  //       },
  //       function error(error) {
  //         console.error(error);
  //       }
  //     );
  //   } else {
  //     console.log('geolocation is not enabled on this browser');
  //   }
  // };
  
  // const reverseGeocodingWithGoogle = (latitude, longitude) => {
  //   fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=
  //     ${latitude},${longitude}&key=${GOOGLE_MAPS_KEY}`
  //   )
  //     .then( res => res.json())
  //     .then(response => {
  //       const addressComponets = response?.results?.find(item => item?.address_components)?.address_components;

  //       addressComponets.forEach(component => {
  //         const componentType = component.types[0];

  //         if (componentType === 'country' && component.short_name === 'CA') {
  //           setIsCA(true);
  //         }
  //       });
  //     })
  //     .catch(status => {
  //       console.log('Request failed.  Returned status of', status);
  //     })
  // };

  const scrollTop = () => {
    setIsOpen(false);
    animateScroll.scrollToTop();
  };

  const toggleIsOpen = () => setIsOpen(!isOpen);

  const handleClickedLink = (label) => {
    trackCustomEvent({
      category: 'Menu',
      label,
      action: 'Clicked',
    });
  };

  const hideMenu = !showMenu && !isOpen;

  // const isBadge = isCA || isCanada;

  // const renderBadge = () =>
  //   isBadge &&
  //     <div className={classnames(`${mainClassName}__badge`)}>
  //       Made with <span>♥</span> in Toronto
  //     </div>;

  return (
    <>
      {<span className={`${mainClassName}__placeholder`} />}
      <div
        className={classnames(mainClassName, {
          [`${mainClassName}--responsive-open`]: isOpen,
          [`${mainClassName}--hide-menu`]: hideMenu,
        })}
      >
        <div className={`${mainClassName}__wrapper`}>
          <div className={`${mainClassName}__content`}>
            <div className={`${mainClassName}__nav-home`}>
              {inMainPage ? (
                <a onClick={scrollTop} className={`${mainClassName}__logo-wrapper`}>
                  {/* {renderBadge()} */}
                  <img
                    src={logo}
                    className={classnames(`${mainClassName}__logo`)}
                    alt="HomeOnTour logo"
                    height="30px"
                    width="auto"
                    loading="eager"
                  />
                </a>
              ) : (
                <Link to="/" className={`${mainClassName}__logo-wrapper`}>
                  {/* {renderBadge()} */}
                  <img
                    src={logo}
                    className={classnames(`${mainClassName}__logo`)}
                    alt="HomeOnTour logo"
                    height="30px"
                    width="auto"
                    loading="eager"
                  />
                </Link>
              )}
            </div>
            <button
              className={`${mainClassName}__hamburger-button`}
              onClick={toggleIsOpen}
              aria-label="Navigation menu"
            >
              {isOpen ? <CloseIcon /> : <HamburgerIcon />}
            </button>
            <span className={`${mainClassName}__mobile-nav-top-space`} />
            {navSections.map(({ label, id, importance, href }, index) =>
              id ? (
                inMainPage ? (
                  <ScrollLink
                    spy={true}
                    smooth={true}
                    duration={500}
                    to={id}
                    key={index}
                    className={classnames(`${mainClassName}__nav-item`, {
                      [`${mainClassName}__nav-item--red`]: importance,
                    })}
                    onClick={() => handleClickedLink(label)}
                    offset={-50}
                  >
                    {label}
                  </ScrollLink>
                ) : (
                  <Link
                    to={`/#${id}`}
                    key={index}
                    className={classnames(`${mainClassName}__nav-item`, {
                      [`${mainClassName}__nav-item--red`]: importance,
                    })}
                    onClick={() => handleClickedLink(label)}
                  >
                    {label}
                  </Link>
                )
              ) : (
                <a
                  key={index}
                  className={classnames(`${mainClassName}__nav-item`, {
                    [`${mainClassName}__nav-item--red`]: importance,
                  })}
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleClickedLink(label)}
                >
                  {label}
                </a>
              )
            )}
            <div
              className={classnames(
                `${mainClassName}__nav-item`,
                `${mainClassName}__nav-item--last-item`
              )}
            >
              <div className={`${mainClassName}__sign-in-wrapper`}>
                <a
                  href="https://admin.homeontour.com"
                  className={`${mainClassName}__sign-in`}
                  onClick={() => handleClickedLink('Sign in')}
                >
                  SIGN IN <SignInIcon className={`${mainClassName}__sign-in-icon`} />
                </a>
              </div>
            </div>
            <span className={`${mainClassName}__mobile-nav-bottom-space`} />
            <div className={`${mainClassName}__contact-data`}>
              <p>
                <a href="mailto:contact@homeontour.com">contact@homeontour.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppBar;
