// import { navSectionId, navSections } from '../navigation/navSections';

const footerData = {
  items: [
    // ...navSections,
    {
      label: 'Privacy Policy',
      to: '/privacy',
    },
    {
      label: 'Terms and Conditions',
      to: '/terms',
    },
  ],
};

export default footerData;
