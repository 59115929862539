export const navSectionId = {
  about: 'about',
  features: 'features',
  testimonials: 'testimonials',
  demos: 'demos',
  packages: 'packages',
  contact: 'contact',
};

export const navSections = [
  {
    label: 'Features',
    id: navSectionId.features,
    importance: true,
  },
  {
    label: 'Demo',
    id: navSectionId.demos,
  },
  {
    label: 'Prices',
    id: navSectionId.packages,
  },
  {
    label: 'Contact',
    id: navSectionId.contact,
  },
  {
    label: 'Support',
    href: 'https://support.homeontour.com/',
  },
];

export default navSections;
