import './footer.scss';

import React from 'react';
import classnames from 'classnames';
import { Link as ScrollLink} from 'react-scroll';
import { Link } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import logo from 'images/logo-white.svg';
import footerData from '../../data/footer/footerData';

const mainClassName = 'footer';

const Footer = () => {

  const handleClickedLink = label => {
    trackCustomEvent({
      category: 'Footer Link',
      label,
      action: 'Clicked'
    });
  };

  return (
    <div className={mainClassName}>
      <div className={`${mainClassName}__wrapper`}>
        <div className={`${mainClassName}__logo-part`}>
          <img loading="lazy" fadeIn="false" className={`${mainClassName}__logo`} src={logo} alt="" />
          <div className={`${mainClassName}__copyright`}>
            {`HomeOnTour ${new Date().getFullYear()}. All rights reserved.`}
          </div>
          <br className={`${mainClassName}__mobile-space`} />
        </div>
        <div className={`${mainClassName}__navigation-wrapper`}>
          <div className={`${mainClassName}__navigation`}>
            {footerData.items.map(({ label, id, href, to }, index) =>
              id ? (
                <ScrollLink
                  spy={true}
                  smooth={true}
                  duration={500}
                  to={id}
                  key={index}
                  className={classnames(`${mainClassName}__nav-item`)}
                >
                  {label}
                </ScrollLink>
              ) : to ? (
                <Link
                  to={to}
                  key={index}
                  className={classnames(`${mainClassName}__nav-item`)}
                  onClick={() => handleClickedLink(label)}
                >
                  {label}
                </Link>
              ) : (
                <a key={index} className={classnames(`${mainClassName}__nav-item`)} href={href}>
                  {label}
                </a>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
