import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { init as AOSInit} from 'aos';
import 'aos/dist/aos.css';

import './layout.scss';
import AppBar from '../AppBar/AppBar';
import Footer from '../Footer/Footer';

import globalData from '../../data/globalData';

const mainClassName = 'layout';

const Layout = ({ children, inMainPage = false }) => {
  useEffect(() => {
    AOSInit({
      disable: 'mobile',
      easing: 'linear',
    });
  }, []);

  return (
    <div className={mainClassName}>
      <Helmet title={globalData.pageTitle}>
        {globalData.metaData}
      </Helmet>
      <AppBar inMainPage={inMainPage} />
      <main className={`${mainClassName}__content`}>{children}</main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
