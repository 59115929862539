import React from 'react';

import homeOnTourLogo from '../images/ipad-cover-photo.png';

const globalData = {
  pageTitle: 'Real Estate Virtual Main Hosting Platform - HomeOnTour',
  metaData: [
    <meta
      name="description"
      content="HomeOnTour is an one stop shop for real estate photographers to host their photos, videos, floor plans and many more. In just few clicks create an immersive and beautiful virtual tour, send it to your client, and let us worry about everything else."
    />,
    <link rel="canonical" href="https://homeontour.com/" />,
    <meta property="og:locale" content="en_US" />,
    <meta property="og:type" content="website" />,
    <meta property="og:title" content="Real Estate Virtual Tour Hosting Platform - HomeOnTour" />,
    <meta
      property="og:description"
      content="HomeOnTour is a one stop shop for real estate photographers to host their photos, videos, floor plans and many more. In just few clicks create an immersive and beautiful virtual tour send it to your client and let us worry about everything else."
    />,
    <meta property="og:url" content="https://homeontour.com/" />,
    <meta property="og:site_name" content="HomeOnTour" />,
    <meta property="og:image" content={homeOnTourLogo} />,
    <meta property="og:image:secure_url" content={homeOnTourLogo} />,
    <meta property="og:image:width" content="1200" />,
    <meta property="og:image:height" content="199" />,
    <meta name="twitter:card" content="summary_large_image" />,
    <meta
      name="twitter:description"
      content="HomeOnTour is an one stop shop for real estate photographers to host their photos, videos, floor plans and many more. In just few clicks create an immersive and beautiful virtual tour, send it to your client, and let us worry about everything else."
    />,
    <meta name="twitter:title" content="Real Estate Virtual Tour Hosting Platform - HomeOnTour" />,
    <meta name="twitter:image" content={homeOnTourLogo} />,
  ],
};

export default globalData;
